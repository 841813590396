.wrapper {
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 auto;
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-right: 2rem;
    padding-left: 2rem;
}

.row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.row.reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.col.reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

[class*="col-xs"] {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.col-xs-1 {
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
}

.col-xs-2 {
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
}

.col-xs-3 {
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
}

.col-xs-4 {
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
}

.col-xs-5 {
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
}

.col-xs-6 {
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
}

.col-xs-7 {
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
}

.col-xs-8 {
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
}

.col-xs-9 {
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
}

.col-xs-10 {
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
}

.col-xs-11 {
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
}

.col-xs-12 {
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
}

.col-xs-offset-1 {
    margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
    margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
    margin-left: 25%;
}

.col-xs-offset-4 {
    margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
    margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
    margin-left: 50%;
}

.col-xs-offset-7 {
    margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
    margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
    margin-left: 75%;
}

.col-xs-offset-10 {
    margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
    margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
    margin-left: 100%;
}

.col-xs {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
}

.start-xs {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
}

.center-xs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
}

.end-xs {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
}

.top-xs {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start;
}

.middle-xs {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
}

.bottom-xs {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end;
}

.around-xs {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around;
}

.between-xs {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
}

.first-xs {
    order: -1;
}

.last-xs {
    order: 1;
}

@media only screen and (min-width: 48em) {
    .container {
        width: 46rem;
    }

    [class*="col-sm"] {
        box-sizing: border-box;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        -moz-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .col-sm-1 {
        -webkit-flex-basis: 8.3333333333%;
        -moz-flex-basis: 8.3333333333%;
        -ms-flex-preferred-size: 8.3333333333%;
        flex-basis: 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-sm-2 {
        -webkit-flex-basis: 16.6666666667%;
        -moz-flex-basis: 16.6666666667%;
        -ms-flex-preferred-size: 16.6666666667%;
        flex-basis: 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-sm-3 {
        -webkit-flex-basis: 25%;
        -moz-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        -webkit-flex-basis: 33.3333333333%;
        -moz-flex-basis: 33.3333333333%;
        -ms-flex-preferred-size: 33.3333333333%;
        flex-basis: 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-sm-5 {
        -webkit-flex-basis: 41.6666666667%;
        -moz-flex-basis: 41.6666666667%;
        -ms-flex-preferred-size: 41.6666666667%;
        flex-basis: 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-sm-6 {
        -webkit-flex-basis: 50%;
        -moz-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        -webkit-flex-basis: 58.3333333333%;
        -moz-flex-basis: 58.3333333333%;
        -ms-flex-preferred-size: 58.3333333333%;
        flex-basis: 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-sm-8 {
        -webkit-flex-basis: 66.6666666667%;
        -moz-flex-basis: 66.6666666667%;
        -ms-flex-preferred-size: 66.6666666667%;
        flex-basis: 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-sm-9 {
        -webkit-flex-basis: 75%;
        -moz-flex-basis: 75%;
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        -webkit-flex-basis: 83.3333333333%;
        -moz-flex-basis: 83.3333333333%;
        -ms-flex-preferred-size: 83.3333333333%;
        flex-basis: 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-sm-11 {
        -webkit-flex-basis: 91.6666666667%;
        -moz-flex-basis: 91.6666666667%;
        -ms-flex-preferred-size: 91.6666666667%;
        flex-basis: 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-sm-12 {
        -webkit-flex-basis: 100%;
        -moz-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-sm-offset-1 {
        margin-left: 8.3333333333%;
    }

    .col-sm-offset-2 {
        margin-left: 16.6666666667%;
    }

    .col-sm-offset-3 {
        margin-left: 25%;
    }

    .col-sm-offset-4 {
        margin-left: 33.3333333333%;
    }

    .col-sm-offset-5 {
        margin-left: 41.6666666667%;
    }

    .col-sm-offset-6 {
        margin-left: 50%;
    }

    .col-sm-offset-7 {
        margin-left: 58.3333333333%;
    }

    .col-sm-offset-8 {
        margin-left: 66.6666666667%;
    }

    .col-sm-offset-9 {
        margin-left: 75%;
    }

    .col-sm-offset-10 {
        margin-left: 83.3333333333%;
    }

    .col-sm-offset-11 {
        margin-left: 91.6666666667%;
    }

    .col-sm-offset-12 {
        margin-left: 100%;
    }

    .col-sm {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -moz-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-flex-basis: 0;
        -moz-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }

    .start-sm {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-sm {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        justify-content: center;
        text-align: center;
    }

    .end-sm {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        -webkit-justify-content: flex-end;
        -moz-justify-content: flex-end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-sm {
        -webkit-box-align: start;
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        -moz-align-items: flex-start;
        align-items: flex-start;
    }

    .middle-sm {
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
    }

    .bottom-sm {
        -webkit-box-align: end;
        -ms-flex-align: end;
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        align-items: flex-end;
    }

    .around-sm {
        -ms-flex-pack: distribute;
        -webkit-justify-content: space-around;
        -moz-justify-content: space-around;
        justify-content: space-around;
    }

    .between-sm {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
    }

    .first-sm {
        order: -1;
    }

    .last-sm {
        order: 1;
    }
}

@media only screen and (min-width: 62em) {
    .container {
        width: 61rem;
    }

    [class*="col-md"] {
        box-sizing: border-box;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        -moz-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .col-md-1 {
        -webkit-flex-basis: 8.3333333333%;
        -moz-flex-basis: 8.3333333333%;
        -ms-flex-preferred-size: 8.3333333333%;
        flex-basis: 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-md-2 {
        -webkit-flex-basis: 16.6666666667%;
        -moz-flex-basis: 16.6666666667%;
        -ms-flex-preferred-size: 16.6666666667%;
        flex-basis: 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-md-3 {
        -webkit-flex-basis: 25%;
        -moz-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-md-4 {
        -webkit-flex-basis: 33.3333333333%;
        -moz-flex-basis: 33.3333333333%;
        -ms-flex-preferred-size: 33.3333333333%;
        flex-basis: 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-md-5 {
        -webkit-flex-basis: 41.6666666667%;
        -moz-flex-basis: 41.6666666667%;
        -ms-flex-preferred-size: 41.6666666667%;
        flex-basis: 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-md-6 {
        -webkit-flex-basis: 50%;
        -moz-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-md-7 {
        -webkit-flex-basis: 58.3333333333%;
        -moz-flex-basis: 58.3333333333%;
        -ms-flex-preferred-size: 58.3333333333%;
        flex-basis: 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-md-8 {
        -webkit-flex-basis: 66.6666666667%;
        -moz-flex-basis: 66.6666666667%;
        -ms-flex-preferred-size: 66.6666666667%;
        flex-basis: 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-md-9 {
        -webkit-flex-basis: 75%;
        -moz-flex-basis: 75%;
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-md-10 {
        -webkit-flex-basis: 83.3333333333%;
        -moz-flex-basis: 83.3333333333%;
        -ms-flex-preferred-size: 83.3333333333%;
        flex-basis: 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-md-11 {
        -webkit-flex-basis: 91.6666666667%;
        -moz-flex-basis: 91.6666666667%;
        -ms-flex-preferred-size: 91.6666666667%;
        flex-basis: 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-md-12 {
        -webkit-flex-basis: 100%;
        -moz-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-md-offset-1 {
        margin-left: 8.3333333333%;
    }

    .col-md-offset-2 {
        margin-left: 16.6666666667%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-4 {
        margin-left: 33.3333333333%;
    }

    .col-md-offset-5 {
        margin-left: 41.6666666667%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-7 {
        margin-left: 58.3333333333%;
    }

    .col-md-offset-8 {
        margin-left: 66.6666666667%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-10 {
        margin-left: 83.3333333333%;
    }

    .col-md-offset-11 {
        margin-left: 91.6666666667%;
    }

    .col-md-offset-12 {
        margin-left: 100%;
    }

    .col-md {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -moz-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-flex-basis: 0;
        -moz-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }

    .start-md {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-md {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        justify-content: center;
        text-align: center;
    }

    .end-md {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        -webkit-justify-content: flex-end;
        -moz-justify-content: flex-end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-md {
        -webkit-box-align: start;
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        -moz-align-items: flex-start;
        align-items: flex-start;
    }

    .middle-md {
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
    }

    .bottom-md {
        -webkit-box-align: end;
        -ms-flex-align: end;
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        align-items: flex-end;
    }

    .around-md {
        -ms-flex-pack: distribute;
        -webkit-justify-content: space-around;
        -moz-justify-content: space-around;
        justify-content: space-around;
    }

    .between-md {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
    }

    .first-md {
        order: -1;
    }

    .last-md {
        order: 1;
    }
}